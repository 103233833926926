<template>
  <b-card>
    <user-delete
      :is-delete-user-sidebar-active.sync="isDeleteUserSidebarActive"
      :selected-user="userData"
      @handle-update-status-client="handleUpdateStatusClient"
    />
    <user-attendance
      :is-user-attendance-sidebar-active.sync="isUserAttendanceSidebarActive"
      :selected-user="userData"
      @handle-update-button-attendance="handleUpdateButtonAttendance"
    />
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="4"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.id.toString()"
            :text="avatarText()"
            :variant="`light-${resolveUserRoleVariant(userData.dni)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-2">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.last_name+ ", " + userData.first_name }}
              </h4>
              <span class="card-text">{{ userData.dni + " " }}</span>
                <b-badge
                  pill
                  :variant="`light-${resolveUserStatusVariant(userData.active)}`"
                  class="text-capitalize"
                > {{ resolveUserStatus(userData.active) }}
                </b-badge>
              <div
                v-if="userData.memberships && userData.memberships.length > 0"
                class="d-flex align-items-center mt-2"
              >
                <!-- User Edit -->
                <div class="d-flex align-items-center mr-2">
                  <b-link
                    :to="{ name: 'apps-clients-edit', params: { id: userData.id } }"
                  >
                    <b-avatar
                      id="edit-user-icon"
                      variant="light-primary"
                      rounded
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        size="20"
                      />
                    </b-avatar>
                    <b-tooltip
                      triggers="hover"
                      target="edit-user-icon"
                      :title="t('actions.editTitleClient')"
                      :delay="{ show: 1000, hide: 50 }"
                    />
                  </b-link>
                </div>
                <!-- User disable -->
                <div
                  v-if="userData.active"
                  class="d-flex align-items-center mr-2"
                >
                  <b-link
                    @click="isDeleteUserSidebarActive = true"
                  >
                    <b-avatar
                      id="delete-user-icon"
                      variant="light-danger"
                      rounded
                    >
                      <feather-icon
                        icon="UserXIcon"
                        size="18"
                      />
                    </b-avatar>
                    <b-tooltip
                      triggers="hover"
                      target="delete-user-icon"
                      :title="t('actions.deleteTitleClient')"
                      :delay="{ show: 1000, hide: 50 }"
                    />
                  </b-link>
                </div>

              </div>
              <div
                v-if="!userData.memberships"
                class="d-flex align-items-center mt-2"
              >
                <!-- User Edit under membership -->
                <div class="d-flex align-items-center mr-2">
                  <b-link
                    :to="{ name: 'apps-clients-edit', params: { id: userData.id } }"
                  >
                    <b-avatar
                      variant="light-primary"
                      rounded
                      id="no-membership-edit-user-icon"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        size="18"
                      />
                    </b-avatar>
                    <b-tooltip
                      triggers="hover"
                      target="no-membership-edit-user-icon"
                      :title="t('actions.editTitleClient')"
                      :delay="{ show: 1000, hide: 50 }"
                    />
                  </b-link>
                </div>
                <!-- User disable under membership -->
                <div
                  v-if="userData.active"
                  class="d-flex align-items-center mr-2"
                >

                  <b-link
                    @click="isDeleteUserSidebarActive = true"
                  >
                    <b-avatar
                      id="delete-user-icon"
                      variant="light-danger"
                      rounded
                    >
                      <feather-icon
                        icon="UserXIcon"
                        size="18"
                      />
                    </b-avatar>
                    <b-tooltip
                      triggers="hover"
                      target="delete-user-icon"
                      :title="t('actions.deleteTitleClient')"
                      :delay="{ show: 1000, hide: 50 }"
                    />
                  </b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- User Stats -->
        <div
          v-if="userData.memberships && userData.memberships.length > 0"
          class="d-flex align-items-center mt-2"
        >

          <div class="d-flex align-items-center mr-2">
            <div
              v-if="userData.memberships[0].rootClient.id == userData.id"
            >
              <h5 class="mb-0">
                {{ t('Clients.type.owner') }}
              </h5>
              <span v-if=" totalRootClient && totalRootClient > 1 ">
                <small>{{ t('Clients.totalDescription') }}
                  <b-badge
                    pill
                    variant="light-info"
                    class="text-capitalize"
                  > {{ totalRootClient }}</b-badge></small>
              </span>
              <span v-else>
                <small>{{ t('Memberships.type.personal') }}</small>
              </span>
            </div>
            <div
              v-else
            >
              <h5 class="mb-0">
                {{ t('Clients.type.member') }}
              </h5>
              <small>{{ t('Memberships.type.group') }}</small>
            </div>
          </div>
          <div
            v-if="userData.memberships[0].rootClient.id != userData.id"
            class="d-flex align-items-center"
          >
            <feather-icon
              icon="LinkIcon"
              size="18"
            />
            <div class="ml-1">
              <h5 class="mb-0">
                {{ t('Clients.type.groupOwner') }}
              </h5>
              <b-link
                :to="{ name: 'apps-clients-view', params: { id: userData.memberships[0].rootClient.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                <small> {{ userData.memberships[0].rootClient.first_name + " "+ userData.memberships[0].rootClient.last_name }}</small>
              </b-link>
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        xl="2"
      >
        <div class="d-flex align-items-center">

          <!-- Column: Attendance date  -->
          <ul class="list-unstyled" v-if="userData.memberships" >
            <li
              v-if="userData.memberships.length > 0 && !userData.memberships[0].isExpired && userData.active"
              class="mb-1"
            >
              <b-button
                variant="primary"
                @click="isUserAttendanceSidebarActive = true"
              >
                <feather-icon
                  icon="CheckIcon"
                /> {{ t('actions.checkAttendance') }}
              </b-button>
            </li>
            <li
              v-if="userData.attendances.length > 0 "
              class="ml-1"
            >
              <small>{{ t('Clients.titleLastAttendance') }} </small>
            </li>
            <li v-if="userData.attendances.length > 0 ">
              <b-badge variant="light-info">
                {{ formatDate(userData.attendances[0].created)+' hs.' }}
              </b-badge>
            </li>
          </ul>

        </div>
      </b-col>
      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Clients.data.registerDate') }}</span>
            </th>
            <td class="pb-50">
              {{ userData.created != null ? dateLatin(userData.created) : '-' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Clients.data.email') }}</span>
            </th>
            <td class="pb-50">
              {{ userData.email != null ? userData.email : '-' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Clients.data.phone') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.phone != null ? userData.phone : '-' }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Clients.data.address') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.address != null ? userData.address : '-' }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="BellIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ t('Clients.data.birthday') }}</span>
            </th>
            <td>
              {{ userData.birth_date != null ? dateLatin(userData.birth_date) : '-' }}
            </td>
          </tr>
        </table>
      </b-col>

    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BLink, BBadge, BTooltip,
} from 'bootstrap-vue'
import { avatarText, dateLatin } from '@core/utils/filter'
import useUsersView from '@/views/Clients/clients-view/useUsersView'
import { ref } from '@vue/composition-api'
import UserDelete from '@/views/Clients/clients-view/UserDelete.vue'
import UserAttendance from '@/views/Clients/clients-view/UserAttendance.vue'

export default {
  components: {
    UserAttendance,
    BTooltip,
    BBadge,
    UserDelete,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BLink,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    totalRootClient: {
      type: String,
    },
  },
  methods: {
    handleUpdateStatusClient() {
      this.userData.active = false
    },
    handleUpdateButtonAttendance() {
      this.showButtonAttendance = false
      this.$emit('handle-update-client')
    },
  },
  setup() {
    const isDeleteUserSidebarActive = ref(false)
    const isUserAttendanceSidebarActive = ref(false)
    const showButtonAttendance = ref(true)

    const {
      resolveUserRoleVariant, t, formatDate, resolveUserStatusVariant, resolveUserStatus, resolveAttendanceButtonVariant,
    } = useUsersView()
    return {
      formatDate,
      isDeleteUserSidebarActive,
      isUserAttendanceSidebarActive,
      showButtonAttendance,
      resolveAttendanceButtonVariant,
      resolveUserStatusVariant,
      resolveUserStatus,
      avatarText,
      dateLatin,
      resolveUserRoleVariant,
      t,
    }
  },
}
</script>

<style>

</style>
