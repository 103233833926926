<template>
  <div>
    <b-card
    no-body
    class="border-primary">
    <b-card-header class="d-flex justify-content-between align-items-center pt-1 pb-0 mb-0 mt-1 text-wrap">
      <ul class="list-unstyled">
        <li>
      <h5 class="mb-1">
        {{t('Planes.lastPlan')}}
      </h5>
        </li>
        <li>
      <b-badge variant="light-info" class="text-left text-wrap">
        {{ plan.name}}
      </b-badge>
        </li>
        <li>
      <small class="text-muted w-100">{{t('Planes.lastUpdate')}} {{ dateLatin(plan.updated) }}</small>
        </li>
      </ul>
    </b-card-header>
    <b-card-body class="mt-0" >
      <ul class="list-unstyled">
        <li>
          {{t('Planes.lastAmount')}}:
          <b-badge variant="light-info">
            ${{ plan.cost }} x {{plan.months}} {{plan.months >1 ? t('months'): t('month')}}
          </b-badge>
        </li>
        <li class="my-25">
          <span class="align-middle"> {{t('dayAWeek')}}: {{ plan.per_week }}</span>
          <span class="align-middle"> - {{t('planStatusText')}}: <b-badge :variant="`light-${resolvePlanStatusVariant(plan.status.id)}`"> {{resolvePlanStatus(plan.status.id)}} </b-badge></span>
        </li>
      </ul>
    </b-card-body>
  </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import { dateLatin } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import useUsersView from '@/views/Clients/clients-view/useUsersView'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },

  },
  setup() {
    const { t,resolvePlanStatusVariant, resolvePlanStatus } = useUsersView()
    return {
      resolvePlanStatusVariant,
      resolvePlanStatus,
      dateLatin,
      t,
    }
  },
}
</script>

<style>

</style>
