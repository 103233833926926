<template xmlns="http://www.w3.org/1999/html">
  <b-modal
    id="user-attendance-sidebar"
    ref="user-attendance-sidebar"
    :title="t('Clients.buttons.addAttendance')"
    :visible="isUserAttendanceSidebarActive"
    backdrop
    no-header
    hide-footer
    @change="(val) => $emit('update:is-user-attendance-sidebar-active', val)"
  >
    <!--    <template #default="{ hide }">-->
    <template>
      <b-row class="w-100 my-4 ">
        <b-col cols="1" />
        <b-col
          cols="11"
          class=""
        >
          {{ t('Clients.date') }} :
          <b-badge
            pill
            variant="light-primary"
          >
            {{ toDateAttendance }} hs.
          </b-badge>
        </b-col>

        <b-col cols="1" />
        <b-col cols="11">
          {{ t('Clients.client') }} :
          <b-badge
            pill
            variant="light-primary"
          >
            {{ selectedUser.last_name+', '+ selectedUser.first_name }}
          </b-badge>
        </b-col>
      </b-row>

      <!-- submit button -->
      <div class="container-fluid  mx-0 my-0 px-0 py-0 mb-2">
        <b-row class="w-100">
          <b-col class="col-5 " />
          <b-col class="col-3 float-right">
            <b-button
              variant="secondary"
              data-dismiss="user-attendance-sidebar"
              :disabled="false"
              @click="hideModal"
            >
              {{ t('Clients.buttons.cancel') }}
            </b-button>
          </b-col>
          <b-col class="col-3 float-right ml-1">
            <b-button
              variant="primary"
              @click="addAttendanceUser(selectedUser, emit)"
            >
              {{ t('Clients.buttons.confirm') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BBadge,
  BButton, BCol, BRow,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useUsersView from '@/views/Clients/clients-view/useUsersView'
import useAttendanceStoreModule from '@/views/Attendances/useAttendanceStoreModule'
import moment from 'moment'

export default {
  components: {
    BBadge,
    BCol,
    BRow,
    BButton,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isUserAttendanceSidebarActive',
    event: 'update:is-user-Attendance-sidebar-active',
  },
  props: {
    isUserAttendanceSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedUser: {
      type: Object,
      default: null,
      required: true,
    },
  },
  computed: {
    toDateAttendance() {
      const now = moment()
      return now.format('DD-MM-YYYY hh:mm')
    },
  },
  methods: {
    hideModal() {
      this.$refs['user-attendance-sidebar'].hide()
    },
  },
  setup(props, { emit }) {
    const USER_ATTENDANCE_APP_STORE_MODULE_NAME = 'app-user-attendance'
    // Register module
    if (!store.hasModule(USER_ATTENDANCE_APP_STORE_MODULE_NAME)) store.registerModule(USER_ATTENDANCE_APP_STORE_MODULE_NAME, useAttendanceStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_ATTENDANCE_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_ATTENDANCE_APP_STORE_MODULE_NAME)
    })

    // Cambiar a consumirlo desde la propia clase de membership
    const { t, addAttendanceUser } = useUsersView()
    return {
      emit,
      addAttendanceUser,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
