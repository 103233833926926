<template>

<!--  El listado de contrataciones se muestra en UserView . Este archivo está deprecado, esperando ser revisado y eliminado -->
  <div>
    <membership-add-new
      :is-add-membership-sidebar-active.sync="isAddMembershipSidebarActive"
      :plan-options="planOptions"
      :user-data="userData"
      :selected-membership="selectedMembership"
      :is-copy-membership="isCopyMembership"
      @refetch-data-membership="refetchDataMembership"
    />

    <membership-edit
      :is-edit-membership-sidebar-active.sync="isEditMembershipSidebarActive"
      :plan-options="planOptions"
      :user-data="userData"
      :selected-membership="selectedMembership"
      @refetch-data-membership="refetchDataMembership"
    />

    <membership-delete
      v-if="selectedMembership"
      :is-delete-membership-sidebar-active.sync="isDeleteMembershipSidebarActive"
      :selected-membership="selectedMembership"
      @refetch-data-membership="refetchDataMembership"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-3"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ t('Clients.clientList.showTitle') }}</label>
            <v-select
              v-model="perPage"

              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <small>{{ t('Memberships.title') }}</small>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="setShowDataForm"
              >
                <span class="text-nowrap">{{ t('Memberships.buttons.addMembership') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        :ref="refMembershipListTable"
        class="position-relative"
        :items="localListMembershipsAssosied"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="t('Memberships.message.no-membership')"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(last_name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.id.toString()"
                :text="avatarText()"
                :variant="`light-${resolveUserRoleVariant(data.item.client.id)}`"
                :to="{ name: 'apps-clients-view', params: { id: data.item.client.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-clients-view', params: { id: data.item.client.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.client.last_name + ", " + data.item.client.first_name }}
            </b-link>
            <small class="text-muted">{{ data.item.client.dni }}</small>
          </b-media>
        </template>

        <!-- Column: Plan -->
        <template #cell(planName)="data">
          <h6 class="text-muted">
            {{ (data.item.plan.name) }}
          </h6>
        </template>

<!--        &lt;!&ndash; Column: membership plan costo &ndash;&gt;
        <template #cell(cost)="data">
          <h6 class="text-muted">
            {{ (data.item.cost ? "$" + data.item.cost : "-") }}
          </h6>
        </template>-->

        <!-- Column: membership plan total payment -->
        <template #cell(totalPayment)="data">
          <h6 class="text-muted">
            {{ (data.item.total_payment ? "$" + round(data.item.total_payment, 2) : "-") }}
          </h6>
        </template>

        <!-- Column: credits -->
        <template #cell(credits)="data">
          <span v-if="data.item.attendance !== data.item.credits ">
            <h6 class="text-muted">{{ (data.item.credits) }}</h6>
          </span>
          <span v-else>
            <b-badge variant="light-danger">
              {{ (data.item.credits) }}
            </b-badge>
          </span>
        </template>

        <!-- Column: available credits -->
        <template #cell(creditsAvailable)="data">
          <span v-if="data.item.available_credits > 0 ">
            <h6 class="text-muted">{{ (data.item.available_credits) }}</h6>
          </span>
          <span v-else>
            <b-badge variant="light-danger">
              {{ (data.item.available_credits) }}
            </b-badge>
          </span>
        </template>

        <!-- Column: assitences  -->
        <template #cell(attendance)="data">
          <span v-if="data.item.attendance != data.item.credits ">
            <h6 class="text-muted">{{ (data.item.attendance) }}</h6>
          </span>
          <span v-else>
            <b-badge variant="light-danger">
              {{ (data.item.attendance) }}
            </b-badge>
          </span>
        </template>

        <!-- Column: date created -->
        <template #cell(creates)="data">
          <h6 class="text-muted">
            {{ data.item.created? dateLatin(data.item.created): '-' }}
          </h6>
        </template>

        <!-- Column: date start -->
        <template #cell(startDate)="data">
          <h6 class="text-muted">
            {{ data.item.start ? dateLatin(data.item.start): '-' }}
          </h6>
        </template>

        <!-- Column: date end -->
        <template #cell(endDate)="data">
          <b-media vertical-align="center">
            <h6 class="text-normal">
              {{ data.item.end? dateLatin(data.item.end): '-' }}
            </h6>
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.isExpired?2:1)}`"
              class="text-capitalize"
            >
              <small>{{ resolveDateStatus(data.item.isExpired) }}</small>
            </b-badge>
          </b-media>
        </template>

        <!-- Column: Actions-->
        <template #cell(actions)="data">
          <b-row>
            <b-col class="col-lg-4">
              <b-link
                on
                variant=""
                @click="selectMembership(data.item)"
              >
                <b-avatar
                  :id="`copy-icon-${data.item.id}`"
                  variant="light-primary"
                  rounded
                >
                  <feather-icon
                    icon="RefreshCwIcon"
                    size="15"
                  />
                </b-avatar>
                <b-tooltip
                  triggers="hover"
                  :target="`copy-icon-${data.item.id}`"
                  :title="t('actions.refresh')"
                  :delay="{ show: 1000, hide: 50 }"
                />
              </b-link>
            </b-col>
            <b-col class="col-lg-4">
              <b-link
                on
                variant=""
                @click="editSelectMembership(data.item)"
              >
                <b-avatar
                  :id="`edit-icon-${data.item.id}`"
                  variant="light-primary"
                  rounded
                >
                  <feather-icon
                    icon="EditIcon"
                    size="15"
                  />
                </b-avatar>
                <b-tooltip
                  triggers="hover"
                  :target="`edit-icon-${data.item.id}`"
                  :title="t('actions.editTitle')"
                  :delay="{ show: 1000, hide: 50 }"
                />
              </b-link>
            </b-col>
            <b-col class="col-lg-4">
              <b-link
                on
                variant="light-danger"
                @click="deleteSelectMembership(data.item)"
              >            <b-avatar
                             :id="`delete-icon-${data.item.id}`"
                             variant="light-danger"
                             rounded
                           >
                             <feather-icon
                               icon="TrashIcon"
                               size="15"
                             />
                           </b-avatar>
                <b-tooltip
                  triggers="hover"
                  :target="`delete-icon-${data.item.id}`"
                  :title="t('actions.deleteTitle')"
                  :delay="{ show: 1000, hide: 50 }"
                />

              </b-link>
            </b-col>
          </b-row>
          <!--          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'apps-clients-view', params: { id: data.item.client.id } }"
              @click="updatePage(data.item.client.id)"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ t('actions.viewTitle') }}</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-clients-edit', params: { id: data.item.client.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ t('actions.editTitle') }}</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ t('actions.deleteTitle') }}</span>
            </b-dropdown-item>
          </b-dropdown>-->
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ t('Clients.clientList.dataMeta') }}: {{ dataMeta.to }} - {{ t('total') }}: {{ totalRootClient }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRootClient"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BPagination, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText, dateLatin } from '@core/utils/filter'
import useUsersView from '@/views/Clients/clients-view/useUsersView'
import MembershipAddNew from '@/views/Memberships/memberships-add/MembershipAddNew.vue'
import MembershipEdit from '@/views/Memberships/memberships-edit/MembershipEdit.vue'
import MembershipDelete from '@/views/Memberships/memberships-delete/MembershipDelete.vue'
import { round } from 'echarts/src/util/number'

export default {

  components: {
    MembershipDelete,
    MembershipEdit,
    BTooltip,
    MembershipAddNew,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    vSelect,
  },
  props: {
    listMembershipsAssosied: {
      type: Array,
      required: true,
      default: () => [],
    },
    totalRootClient: {
      type: String,
    },
    planOptions: {
      type: Array,
      default: null,
    },
    userData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {

    }
  },
  beforeUpdate() {
    this.setValue()
  },
  created() {
    this.setValue()
  },

  methods: {
    round,
    setValue() {
      this.localListMembershipsAssosied = [...this.listMembershipsAssosied]
    },
    selectMembership(item) {
      this.selectedMembership = item
      this.isCopyMembership = true
      this.isAddMembershipSidebarActive = item.plan.status.id === 1
    },

    editSelectMembership(item) {
      this.selectedMembership = item
      this.isEditMembershipSidebarActive = true
    },

    deleteSelectMembership(item) {
      this.selectedMembership = item
      this.isDeleteMembershipSidebarActive = true
    },

    refetchDataMembership() {
     // this.$emit('handle-update-client')
    },

    setShowDataForm() {
      this.isCopyMembership = false
      this.isAddMembershipSidebarActive = true
    },
  },

  setup() {
    const selectedMembership = ref()
    const isAddMembershipSidebarActive = ref(false)
    const isEditMembershipSidebarActive = ref(false)
    const isDeleteMembershipSidebarActive = ref(false)
    const isCopyMembership = ref(false)
    const localListMembershipsAssosied = ref()
    const {
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refMembershipListTable,
      // UI
      resolveUserRoleVariant,
      resolveUserStatus,
      resolveUserStatusVariant,
      resolveDateStatus,
      t,
    } = useUsersView()

    return {
      selectedMembership,
      localListMembershipsAssosied,
      isAddMembershipSidebarActive,
      isEditMembershipSidebarActive,
      isDeleteMembershipSidebarActive,
      isCopyMembership,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refMembershipListTable,

      // Filter
      avatarText,
      dateLatin,

      // UI
      resolveUserRoleVariant,
      resolveUserStatus,
      resolveUserStatusVariant,
      resolveDateStatus,
      t,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
