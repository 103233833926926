<template xmlns="http://www.w3.org/1999/html">
  <b-modal
    id="delete-user-sidebar"
    ref="delete-user-sidebar"
    :title="t('Clients.deleteClient')"
    :visible="isDeleteUserSidebarActive"
    backdrop
    no-header
    hide-footer
    @change="(val) => $emit('update:is-delete-user-sidebar-active', val)"
  >
    <!--    <template #default="{ hide }">-->
    <template>
      <b-row class="w-100 my-4 ">
        <b-col cols="1" />
        <b-col
          cols="11"
          class=""
        >
          ¿ {{ t('Clients.message.delete-user') }}
        </b-col>
        <b-col cols="1" />
        <b-col cols="11">
          <b-badge
            pill
            variant="light-primary"
          >
            {{ selectedUser.last_name+', '+ selectedUser.first_name }}
          </b-badge> ?
        </b-col>
      </b-row>

      <!-- submit button -->
      <div class="container-fluid  mx-0 my-0 px-0 py-0 mb-2">
        <b-row class="w-100">
          <b-col class="col-5 " />
          <b-col class="col-3 float-right">
            <b-button
              variant="secondary"
              data-dismiss="delete-user-sidebar"
              :disabled="false"
              @click="hideModal"
            >
              {{ t('Clients.buttons.cancel') }}
            </b-button>
          </b-col>
          <b-col class="col-3 float-right ml-1">
            <b-button
              variant="danger"
              @click="deleteUser(selectedUser, emit)"
            >
              {{ t('Clients.buttons.delete') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BBadge,
  BButton, BCol, BRow,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useUsersView from '@/views/Clients/clients-view/useUsersView'
import userStoreModule from '@/views/Clients/userStoreModule'

export default {
  components: {
    BBadge,
    BCol,
    BRow,
    BButton,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isDeleteUserSidebarActive',
    event: 'update:is-delete-user-sidebar-active',
  },
  props: {
    isDeleteUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedUser: {
      type: Object,
      default: null,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$refs['delete-user-sidebar'].hide()
    },
  },
  setup(props, { emit }) {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

   // Cambiar a consumirlo desde la propia clase de membership
    const { t, deleteUser } = useUsersView()
    return {
      emit,
      deleteUser,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
