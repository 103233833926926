<template>
  <div>
    <user-pre-paid
      :user-data="userData"
      :membership-prepaid="membershipPrepaid"
      :is-user-prepaid-sidebar-active.sync="isUserPrepaidSidebarActive"
      @handle-update-prepaid="handleUpdatePrepaid"
    />
    <b-card
      no-body
    >
      <b-card-header class="d-flex justify-content-between align-items-center pt-0 pb-0 mb-0">
        <ul class="list-unstyled ">
          <li />
        </ul>
      </b-card-header>
      <b-card-body class="mt-1">
        <b-row>
          <b-col
            cols="12"
            md="10"
          >
            El cliente {{ userData.last_name }}, {{ userData.first_name }} tiene una contratación
            <b-badge variant="light-info">
               pagada el día {{ dateLatin(membershipPrepaid.created, 'dd/MM/yyyy') }}
            </b-badge>
            por el plan {{ membershipPrepaid.plan.name }} X {{ membershipPrepaid.plan.months }} mes/es. Se activará automáticamente el día
            <b-badge variant="light-info">{{dateLatin(membershipPrepaid.start, 'dd/MM/yyyy') }}</b-badge> con
            <b-badge variant="light-info">vencimiento {{dateLatin(membershipPrepaid.end, 'dd/MM/yyyy') }}</b-badge>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              class="btn-outline-secondary float-right"
              @click="isUserPrepaidSidebarActive = true"
            >{{ t('actions.activateNow') }}</b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton, BCol, BRow,
} from 'bootstrap-vue'
import { dateLatin } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import useUsersView from '@/views/Clients/clients-view/useUsersView'
import UserPrePaid from '@/views/Clients/clients-view/UserPrePaid.vue'
import { ref } from '@vue/composition-api'

export default {
  directives: {
    Ripple,
  },
  components: {
    UserPrePaid,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    membershipPrepaid: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleUpdatePrepaid() {
      this.$emit('handle-update-client')
    },
  },

  setup() {
    const isUserPrepaidSidebarActive = ref(false)
    const { t } = useUsersView()
    return {
      isUserPrepaidSidebarActive,
      dateLatin,
      t,
    }
  },
}
</script>

<style>

</style>
