<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-clients-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card
            :user-data="userData"
            :total-root-client="totalRootClient"
            @handle-update-client="handleUpdateClient"
          />
        </b-col>
        <b-col
          v-if="userData.memberships && userData.memberships.length > 0 && userData.memberships[0].plan"
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-user-plan-card :plan="userData.memberships[0].plan" />
        </b-col>
        <b-col
          v-else
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-no-plan-card />
        </b-col>
      </b-row>

      <b-row v-if="membershipPrepaid">
        <b-col
          cols="12"
          md="12"
          xl="12"
          lg="12"
        >
          <user-view-membership-prepaid
            :membership-prepaid="membershipPrepaid"
            :user-data="userData"
            @handle-update-client="handleUpdateClient"
          />
        </b-col>
      </b-row>
      <template>
        <b-row>
          <b-col
            cols="12"
            md="12"
            xl="12"
            lg="12"
          >
            <!--          <user-view-memberships-list
            :list-memberships-assosied="listMembershipsAssosied"
            :total-root-client="totalRootClient"
            :plan-options="planOptionsActive"
            :user-data="userData"

          />-->

            <div>
              <membership-add-new
                :is-add-membership-sidebar-active.sync="isAddMembershipSidebarActive"
                :plan-options-active="planOptionsActive"
                :payment-methods-options-active="paymentMethodsOptionsActive"
                :user-data="userData"
                :selected-membership="selectedMembership"
                :is-copy-membership="isCopyMembership"
                @handle-update-client="handleUpdateClient"
              />

              <membership-edit
                :is-edit-membership-sidebar-active.sync="isEditMembershipSidebarActive"
                :plan-options="planOptions"
                :payment-methods-options-active="paymentMethodsOptionsActive"
                :user-data="userData"
                :selected-membership="selectedMembership"
                @handle-update-client="handleUpdateClient"
              />

              <membership-delete
                v-if="selectedMembership"
                :is-delete-membership-sidebar-active.sync="isDeleteMembershipSidebarActive"
                :selected-membership="selectedMembership"
                @handle-update-client="handleUpdateClient"
              />
              <!-- Table Container Card -->
              <b-card
                no-body
                class="mb-3"
              >
                <div class="m-2">

                  <!-- Table Top -->
                  <b-row>

                    <!-- Per Page -->
                    <b-col
                      cols="12"
                      md="4"
                      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                      <label>{{ t('Clients.clientList.showTitle') }}</label>
                      <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block mx-50"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                      <small>{{ t('Memberships.title') }}</small>
                    </b-col>
                    <!-- Search -->
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <div class="d-flex align-items-center justify-content-end">
                        <b-button
                          variant="primary"
                          @click="setShowDataForm"
                        >
                          <span class="text-nowrap">{{ t('Memberships.buttons.addMembership') }}</span>
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>

                </div>

                <b-table
                  ref="refMembershipListTable"
                  class="position-relative"
                  :items="listMembershipsAssosied"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  :sort-by.sync="sortBy"
                  show-empty
                  :empty-text="t('Memberships.message.no-membership')"
                  :sort-desc.sync="isSortDirDesc"
                >

                  <!-- Column: User -->
                  <template #cell(client)="data">
                    <b-media vertical-align="center">
                      <b-link
                        :to="{ name: 'apps-clients-view', params: { id: data.item.client.id } }"
                        class="font-weight-bold d-block text-nowrap"
                      >
                        {{ data.item.client.last_name + ", " + data.item.client.first_name }}
                      </b-link>
                      <small class="text-muted">{{ data.item.client.dni }}</small>
                    </b-media>
                  </template>

                  <!-- Column: Plan -->
                  <template #cell(plan)="data">
                    <small class="text-muted">
                      {{ (data.item.plan.name) }}
                    </small>

                    <b-badge
                        v-if="(data.item.total_payment != data.item.cost ) && (data.item.discount_percentage !=null) && (data.item.discount_percentage > 0)"
                        variant="light-info"
                    >
                      {{ data.item.discount_percentage + "% off" }}
                    </b-badge>

                  </template>

                  <!-- Column: membership plan costo -->
                  <template #cell(cost)="data">
                    <h6 class="text-muted text-center">
                      {{ (data.item.cost ? "$" + data.item.cost : "-") }}
                    </h6>
                  </template>

                  <!-- Column: Membership debt -->
                  <template #cell(debt)="data">
                    <h6 class="text-muted text-center">
                      <span v-if="data.item.debt > 0">
                        <b-badge variant="light-warning">
                          {{ ("$" + data.item.debt) }}
                        </b-badge>
                      </span>
                      <span v-else> -
                      </span>
                    </h6>
                  </template>

                  <!-- Column: membership plan total payment -->
                  <template #cell(totalPayment)="data">
                    <h6 class="text-muted text-center">
                      {{ (data.item.total_payment ? "$" + round(data.item.total_payment, 2) : "-") }}
                    </h6>
                  </template>

                  <!-- Column: payment method -->
                  <template #cell(paymentMethod)="data">
                    <h6 class="text-muted text-center">
            <span v-if="data.item.paymentMethod != null">
                {{data.item.paymentMethod.name}}
            </span>
                      <span v-else> -
            </span>
                    </h6>
                  </template>
                  <!-- Column: available credits -->
                  <template #cell(creditsAvailable)="data">
                    <span v-if="data.item.available_credits > 0 ">
                      <h6 class="text-muted text-center">{{ (data.item.available_credits) }}</h6>
                    </span>
                    <span v-else>
                      <b-badge variant="light-danger">
                        {{ (data.item.available_credits) }}
                      </b-badge>
                    </span>
                  </template>

                  <!-- Column: credits -->
                  <template #cell(credits)="data">
                    <span v-if="data.item.attendance !== data.item.credits ">
                      <h6 class="text-muted text-center">{{ (data.item.credits) }}</h6>
                    </span>
                    <span v-else>
                      <b-badge variant="light-danger">
                        {{ (data.item.credits) }}
                      </b-badge>
                    </span>
                  </template>

                  <!-- Column: assitences  -->
                  <template #cell(attendance)="data">
                    <span v-if="data.item.attendance != data.item.credits ">
                      <h6 class="text-muted text-center">{{ (data.item.attendance) }}</h6>
                    </span>
                    <span v-else>
                      <b-badge variant="light-danger">
                        {{ (data.item.attendance) }}
                      </b-badge>
                    </span>
                  </template>

                  <!-- Column: date created -->
                  <template #cell(created)="data">
                    <h6 class="text-muted">
                      {{ data.item.created ? dateLatin(data.item.created) : '-' }}
                    </h6>
                  </template>

                  <!-- Column: date start -->
                  <template #cell(start)="data">
                    <h6 class="text-muted">
                      {{ data.item.start ? dateLatin(data.item.start) : '-' }}
                    </h6>
                  </template>

                  <!-- Column: date end -->
                  <template #cell(end)="data">
                    <b-media vertical-align="center">
                      <h6 class="text-normal">
                        {{ data.item.end ? dateLatin(data.item.end) : '-' }}
                      </h6>
                      <b-badge
                        pill
                        :variant="`light-${resolveDateStatusVariant((data.item.isExpired || data.item.isExpiresToday) ?true:false)}`"
                        class="text-capitalize"
                      >
                        <small>{{ resolveDateStatus(data.item.isExpired, data.item.isExpiresToday) }}</small>
                      </b-badge>
                    </b-media>
                  </template>

                  <!-- Column: Actions-->
                  <template #cell(actions)="data">
                    <b-row>
                      <b-col
                        cols="12"
                        xl="4"
                        class="no-resize-col"
                      >
                        <b-link
                          class="flex-fill"
                          on
                          @click="selectMembership(data.item)"
                        >
                          <feather-icon
                            :id="`copy-icon-${data.item.id}`"
                            class="flex-fill"
                            icon="RefreshCwIcon"
                            :size="sizeImage"
                          />
                          <b-tooltip
                            triggers="hover"
                            :target="`copy-icon-${data.item.id}`"
                            :title="t('actions.refresh')"
                            :delay="timeTooltip"
                          />
                        </b-link>

                      </b-col>
                      <b-col
                        cols="12"
                        xl="4"
                        class="no-resize-col"
                      >
                        <b-link
                          on
                          @click="editSelectMembership(data.item)"
                        >
                          <feather-icon
                            :id="`edit-icon-${data.item.id}`"
                            icon="EditIcon"
                            :size="sizeImage"
                          />
                          <b-tooltip
                            triggers="hover"
                            :target="`edit-icon-${data.item.id}`"
                            :title="t('actions.editTitle')"
                            :delay="timeTooltip"
                          />
                        </b-link>
                      </b-col>
                      <b-col
                        cols="12"
                        xl="4"
                        class="no-resize-col"
                      >
                        <b-link
                          on
                          @click="deleteSelectMembership(data.item)"
                        >
                          <feather-icon
                            :id="`delete-icon-${data.item.id}`"
                            icon="TrashIcon"
                            :size="sizeImage"
                            class="trash-danger"
                          />
                          <b-tooltip
                            triggers="hover"
                            :target="`delete-icon-${data.item.id}`"
                            :title="t('actions.deleteTitle')"
                            :delay="timeTooltip"
                          />
                        </b-link>
                      </b-col>
                    </b-row>
                  </template>
                </b-table>
                <div class="mx-2 mb-2">
                  <b-row>

                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted">{{ t('Clients.clientList.dataMeta') }}: {{ dataMeta.to }} - {{
                        t('total')
                      }}: {{ totalRootClient }}</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRootClient"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>

                    </b-col>

                  </b-row>
                </div>
              </b-card>
            </div>

          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>

<script>
import {
  BRow, BCol, BAlert, BLink, BCard, BButton, BTable, BMedia, BAvatar, BBadge, BTooltip, BPagination,
} from 'bootstrap-vue'
import store from '@/store'
import { avatarText, dateLatin } from '@core/utils/filter'
import { onUnmounted, ref } from '@vue/composition-api'

import vSelect from 'vue-select'
import planStoreModule from '@/views/Plans/planStoreModule'
import useUsersView from '@/views/Clients/clients-view/useUsersView'
import UserViewNoPlanCard from '@/views/Clients/clients-view/UserViewUserNoPlanCard.vue'
import usePlansList from '@/views/Plans/plans-list/usePlansList'
import UserViewMembershipPrepaid from '@/views/Clients/clients-view/UserViewMembershipPrepaid.vue'
import { round } from 'echarts/src/util/number'
import MembershipAddNew from '@/views/Memberships/memberships-add/MembershipAddNew.vue'
import MembershipEdit from '@/views/Memberships/memberships-edit/MembershipEdit.vue'
import MembershipDelete from '@/views/Memberships/memberships-delete/MembershipDelete.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import UserViewMembershipsList from './UsersViewMembershipsList.vue'
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'

export default {
  components: {
    MembershipDelete,
    MembershipEdit,
    MembershipAddNew,
    vSelect,
    BPagination,
    BTooltip,
    BBadge,
    BAvatar,
    BMedia,
    BTable,
    BButton,
    BCard,
    UserViewMembershipPrepaid,
    UserViewNoPlanCard,
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewMembershipsList,
    UserViewUserPermissionsCard,

  },
  watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        // The route parameter (id) has changed, fetch the new data
        this.updateClient()
      }
    },
  },

  created() {
    this.updateClient()
  },
  methods: {
    avatarText,
    async handleUpdateClient() {
      this.updateClient()
    },

    updateClient() {
      this.fetchUser()
      this.getAllPlansAndMethods()
    },

    round,
    selectMembership(item) {
      if (item.plan.status.id !== 1) {
        this.showMembershipCloneErrorToast()
        return
      }

      this.selectedMembership = item
      this.isCopyMembership = true
      this.isAddMembershipSidebarActive = (item.plan.status.id === 1)
    },
    showMembershipCloneErrorToast() {
      const toastConfig = {
        component: ToastificationContent,
        props: {
          title: 'No se puede clonar la contratación. El plan no está disponible. Deberá crear una nueva.',
          icon: 'AlertTriangleIcon',
          variant: 'warning',
        },
      }

      this.toast(toastConfig)
    },

    editSelectMembership(item) {
      this.selectedMembership = item
      this.isEditMembershipSidebarActive = true
    },

    deleteSelectMembership(item) {
      this.selectedMembership = item
      this.isDeleteMembershipSidebarActive = true
    },

    refetchDataMembership() {
      this.$emit('handle-update-client')
    },

    setShowDataForm() {
      this.isCopyMembership = false
      this.isAddMembershipSidebarActive = true
    },
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const PLAN_APP_STORE_MODULE_NAME = 'app-plan'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(PLAN_APP_STORE_MODULE_NAME)) store.unregisterModule(PLAN_APP_STORE_MODULE_NAME)
    })

    const selectedMembership = ref()
    const isAddMembershipSidebarActive = ref(false)
    const isEditMembershipSidebarActive = ref(false)
    const isDeleteMembershipSidebarActive = ref(false)
    const isCopyMembership = ref(false)

    const {
      fetchUser,
      listMembershipsAssosied,
      userData,
      totalRootClient,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refMembershipListTable,
      refetchListMembership,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatus,
      resolveDateStatus,
      resolveUserStatusVariant,
      resolveDateStatusVariant,
      membershipPrepaid,
      // Extra Filters
      planFilter,
      statusFilter,
      t,
      toast,
    } = useUsersView()

    const {
      planOptionsActive, paymentMethodsOptionsActive, planOptions, getPlans, getAllPlansAndMethods,
    } = usePlansList()

    const sizeImage = '16'
    const timeTooltip = { show: 1000, hide: 50 }

    return {
      fetchUser,
      sizeImage,
      timeTooltip,
      listMembershipsAssosied,
      userData,
      dateLatin,
      totalRootClient,
      planOptions,
      planOptionsActive,
      paymentMethodsOptionsActive,
      getPlans,
      getAllPlansAndMethods,
      totalUsers,

      selectedMembership,
      isAddMembershipSidebarActive,
      isEditMembershipSidebarActive,
      isDeleteMembershipSidebarActive,
      isCopyMembership,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refMembershipListTable,
      refetchListMembership,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatus,
      resolveDateStatus,
      resolveUserStatusVariant,
      resolveDateStatusVariant,
      membershipPrepaid,

      // Extra Filters
      planFilter,
      statusFilter,
      t,
      toast,
    }
  },
}
</script>

<style lang="scss">
.trash-danger {
  color: #ea5455;
}
</style>
