<template>
  <b-card
    no-body
    class="border-primary">
    <b-card-header class="d-flex justify-content-between align-items-center pt-2 pb-0 mb-0">
      <ul class="list-unstyled ">
        <li>
          <h5 class="mb-0">
            {{t('Planes.lastPlan')}}
          </h5>
        </li>
      </ul>
    </b-card-header>
    <b-card-body class="mt-1" >
      <ul class="list-unstyled">
        <li>
          <small class="text-muted w-100">{{t('Planes.message.no-data')}}</small>
        </li>
        <li class="mt-2">
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import { dateLatin } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import useUsersView from '@/views/Clients/clients-view/useUsersView'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },

  setup() {
    const { t } = useUsersView()
    return {
      dateLatin,
      t,
    }
  },
}
</script>

<style>

</style>
